import api from "@/core/services/api.service.js";
import {
  setTokenBridge,
  setTokenSatuSehat,
  setRefreshTokenBridge,
  setSsOrganizationId,
  setSsLocationDefault,
} from "@/core/services/jwt.service.js";
import {
  UPDATE_SS_CLIENT_ID,
  UPDATE_SS_CLIENT_SECRET,
  UPDATE_SS_ORGANIZATION_ID,
  UPDATE_SS_ACCESS_TOKEN,
} from "@/core/services/store/tokenstore.module";
api.setHeaderBridge();
var isAlert = false;

// Login To API Bridge & Satu Sehat
let login_data_api_bridge = {
  username: process.env.VUE_APP_API_BRIDGE_USERNAME,
  password: process.env.VUE_APP_API_BRIDGE_PASSWORD,
  grant_type: "password",
  scope: "*",
  ss_client_id: process.env.VUE_APP_API_BRIDGE_SS_CLIENT_ID,
  ss_client_secret: process.env.VUE_APP_API_BRIDGE_SS_CLIENT_SECRET
};

const CrudModuleBridge = {

  async submit(data, route, is_reload = false) {
    let state = "",
      validationError = "",
      success = "";
    // SwalLoading
    SwalLoading.fire();
    await api.bridge
      .post(route, data)
      .then((response) => {

        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
          console.log('error disini')
        } else if (response.status == 500) {
          Swal.fire(
            "Gagal",
            "Terjadi Kesalahan Pada Sistem, Silahkan Hubungi Tim Wellmagic",
            "error"
          );
        } else if (response.data.hasOwnProperty("issue")) {
          console.log('gal masuk kesini', response.data.issue[0].details.text)
          Swal.fire(
            "Gagal asdasd",
            "ausdhuashdusahd",
            "error"
          );
          state = "success";
          let successObj = {
            title: "error",
            message: response.data.issue[0].details.text,
          }
          success = successObj
        } else {
          state = "success";
          success = response.data;
          if(is_reload) {
            location.reload();
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          state = "error";
          validationError = error.response.data;
        } else if (error.response.status == 401) {
          api.bridge.post("oauth/token", login_data_api_bridge).then((response) => {
            let responseData = response.data
            this.successGetTokenBridge(responseData)
            this.submit(data, route, true)
          })
        }
      })
      .finally(() => {
        // Close SwalLoading
        SwalLoading.close();
      });
    return {
      state: state,
      error: validationError,
      success: success,
    };
  },

  successGetTokenBridge(data) {
    console.log('data =======================', data)
    setTokenBridge(data.bridge.access_token)
    setRefreshTokenBridge(data.bridge.refresh_token)
    setTokenSatuSehat(data.satusehat.access_token)
    api.setHeaderBridge(data.bridge.access_token)
  },

  async submitWithoutAlert(data, route) {
    let state = "",
      validationError = "",
      success = "";
    await api.bridge
      .post(route, data)
      .then((response) => {
        console.log("response lahhhh ubah", response);

        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else if (response.status == 500) {
          Swal.fire(
            "Gagal",
            "Terjadi Kesalahan Pada Sistem, Silahkan Hubungi Tim Wellmagic",
            "error"
          );
        } else if (response.data.hasOwnProperty("issue")) {
          console.log('gal masuk kesini')
          Swal.fire(
            "Gagal",
            response.data.issue[0].details.text,
            "error"
          );
          state = "success";
          let successObj = {
            title: "error",
            message: response.data.issue[0].details.text,
          }
          success = successObj
        } else {
          state = "success";
          success = response.data;
        }
      })
      .catch((error) => {
        console.log("error lahhhhhhhh", error);
        if (error.response.status == 422) {
          state = "error";
          validationError = error.response.data;
        } else if (error.response.status == 401) {
          api.bridge.post("oauth/token", login_data_api_bridge).then((response) => {
            let responseData = response.data
            this.successGetTokenBridge(responseData)
            this.submit(data, route)
          })
        }
      })
      .finally(() => {
      });
    return {
      state: state,
      error: validationError,
      success: success,
    };
  },

  async paginate(route, slug = "") {
    let data = {},
      meta = {};
    await api.bridge
      .get(route, slug)
      .then((response) => {
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1;
          }
          meta = response.data.meta;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return { data: data, meta: meta };
  },

  async getNoPgination(route, slug = "") {
    let data = {},
      meta = {};
    await api.bridge
      .get(route, slug)
      .then((response) => {
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return { data: data };
  },


  async get(route, params = "") {
    let data = null;
    await api.bridge
      .get(route, params)
      .then((response) => {
        console.log('response di module brid ========================', response)
        if (response.status == 200) {
          data = response.data
        }
      })
      .catch((error) => {
        console.log("error di module bridge", error);
        // api.bridge.post("oauth/token", login_data_api_bridge).then((response) => {
        //   console.log('relogin satusehat', response)
        //   let responseData = response.data
        //   this.successGetTokenBridge(responseData)
        //   setTimeout(() => {
        //     this.get(route, params)
        //   }, 10000);
        // })
        if (error.response.status == 401) {
          api.bridge.post("oauth/token", login_data_api_bridge).then((response) => {
            let responseData = response.data
            this.successGetTokenBridge(responseData)
            this.get(route, params)
          })
        }
      });
    return data;
  },

  async getAccessByUser(route, params = "") {
    let data = null;
    await api.bridge
      .get(route, params)
      .then((response) => {
        console.log("response", response);
        // If Data Exist
        if (response.status != 204 && response.status != 401) {
          data = response.data;
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1;
          }
        } else if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return data;
  },

  async delete(route) {
    let result = await Swal.fire({
      title: "Hapus Data?",
      text: "Data yang dihapus tidak bisa dikembalikan",
      icon: "question",
      showCancelButton: true,
    }).then(async function (result) {
      if (result.value) {
        // SwalLoading
        SwalLoading.fire();
        // Delete Data
        return await api.bridge
          .delete(route)
          .then((response) => {
            console.log("response", response);
            if (response.status == 401 && isAlert == false) {
              isAlert = true;
              Swal.fire(
                "Gagal",
                "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
                "error"
              );
            } else {
              // Success SweetAlert
              // Swal.fire(response.data.title, response.data.message, "success");
              Swal.fire({
                title: response.data.title,
                text: response.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              return true;
            }
          })
          .catch((error) => {
            console.log("error", error);
            // Close SwalLoading
            SwalLoading.close();
            return false;
          });
      }
    });
    return result;
  },

};

export default CrudModuleBridge;
